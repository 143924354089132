







import { Component, Prop, Emit } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import G6 from '@antv/g6'
const { uniqueId } = G6.Util
import { isNumber, isArray } from '@antv/util'

@Component({
  name: 'LinkPicture',
})
export default class LinkPicture extends VueBase {
  @Prop() data!: any

  @Emit('openRight')
  private openRight(flag: any, node: any) {
    return [flag, node]
  }
  private json_data: any = require('../../json_data.json')

  private souce_data: any = {}
  private GraphHandel: any = null

  private init() {
    // 测试数据 this.json_data.data.graphsv3-真实数据data
    const nodes = this.data.project_nodes.map((item: any) => {
      return {
        project__name: item.project__name,
        project_id: item.project_id,
        id: item.id,
        project_path: item.project_path,
        label: item.project__name,
        type: 'aggregated-node',
        stroke: '#4a72ae',
        img: `/upload/assets/links/db.png`,
        opacity: 0.8,
        stateStyles: {
          selected: {
            // #26A6F5
            shadowColor: '#4a72ae',
            shadowBlur: 20,
            fill: '#fff',
            stroke: '#4a72ae',
            lineWidth: 2,
            cursor: 'pointer',
          },
          hover: {
            shadowColor: '#4a72ae',
            shadowBlur: 8,
            fill: '#fff',
            stroke: '#4a72ae',
            lineWidth: 2,
            cursor: 'pointer',
          },
        },
      }
    })
    const edges = this.data.project_vecs.map((item: any) => {
      return {
        source: item.from,
        target: item.to,
        project_path: item.project_path,
        title: 'title',
      }
    })
    this.souce_data = {
      nodes,
      edges,
    }
    console.log('this.souce_data', this.souce_data)
    const container = document.getElementById('mountNode2') as HTMLElement
    const width = container.scrollWidth
    const height = container.scrollHeight || 500
    this.run(width, height)
  }

  private async run(height: number, width: number) {
    const darkBackColor = 'rgb(43, 47, 51)'
    const disableColor = '#777'
    const theme = 'dark'
    const subjectColors = [
      '#5F95FF', // blue
      '#61DDAA',
      '#65789B',
      '#F6BD16',
      '#7262FD',
      '#78D3F8',
      '#9661BC',
      '#F6903D',
      '#008685',
      '#F08BB4',
    ]

    const colorSets = G6.Util.getColorSetsBySubjectColors(
      subjectColors,
      darkBackColor,
      theme,
      disableColor
    )
    const NODESIZEMAPPING = 'degree'
    const SMALLGRAPHLABELMAXLENGTH = 5
    let labelMaxLength = SMALLGRAPHLABELMAXLENGTH
    const DEFAULTNODESIZE = 20
    const DEFAULTAGGREGATEDNODESIZE = 53
    const NODE_LIMIT = 40 // TODO: find a proper number for maximum node number on the canvas

    let currentUnproccessedData: any = { nodes: [], edges: [] }
    let nodeMap: any = {}
    let aggregatedNodeMap: any = {}
    let hiddenItemIds: any = []
    let largeGraphMode: any = true
    let cachePositions: any = {}
    let manipulatePosition: any = undefined
    let descreteNodeCenter: any = ''
    let layout: any = {
      type: '',
      instance: null,
      destroyed: true,
    }
    let expandArray = []
    let collapseArray = []
    let shiftKeydown = false
    const duration = 2000
    const animateOpacity = 0.6
    const animateBackOpacity = 0.1
    const virtualEdgeOpacity = 0.1
    const realEdgeOpacity = 0.2
    const global = {
      node: {
        style: {
          fill: '#2B384E',
        },
        labelCfg: {
          style: {
            fill: '#acaeaf',
            stroke: '#191b1c',
          },
        },
        stateStyles: {
          focus: {
            fill: '#2B384E',
          },
        },
      },
      edge: {
        style: {
          stroke: '#acaeaf',
          realEdgeStroke: '#acaeaf', //'#f00',
          realEdgeOpacity,
          strokeOpacity: realEdgeOpacity,
        },
        labelCfg: {
          style: {
            fill: '#acaeaf',
            realEdgeStroke: '#acaeaf', //'#f00',
            realEdgeOpacity: 0.5,
            stroke: '#191b1c',
          },
        },
        stateStyles: {
          focus: {
            stroke: '#fff', // '#3C9AE8',
          },
        },
      },
    }
    const minimap = new G6.Minimap({
      container: 'minimap',
      size: [100, 100],
    })
    const tooltip = new G6.Tooltip({
      offsetX: 20,
      offsetY: 30,
      itemTypes: ['edge'],
      fixToNode: [0.5, 0.5],
      // custom the tooltip's content
      // 自定义 tooltip 内容
      getContent: (e: any) => {
        return `查看当前链路Service Beta污点流`
      },
      shouldBegin: (e: any) => {
        console.log(e.target)
        let res = true
        return res
      },
    })
    // Custom super node
    G6.registerNode(
      'aggregated-node',
      {
        draw(cfg: any, group: any) {
          let width = 53,
            height = 27
          const style = cfg.style || {}
          const colorSet = cfg.colorSet || colorSets[0]

          // halo for hover
          group.addShape('rect', {
            attrs: {
              x: -width * 0.55,
              y: -height * 0.6,
              width: width * 1.1,
              height: height * 1.2,
              fill: colorSet.mainFill,
              opacity: 0.9,
              lineWidth: 0,
              radius: (height / 2 || 13) * 1.2,
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'halo-shape',
            visible: false,
          })

          // focus stroke for hover
          group.addShape('rect', {
            attrs: {
              x: -width * 0.55,
              y: -height * 0.6,
              width: width * 1.1,
              height: height * 1.2,
              fill: colorSet.mainFill, // '#3B4043',
              stroke: '#AAB7C4',
              lineWidth: 1,
              lineOpacty: 0.85,
              radius: (height / 2 || 13) * 1.2,
            },
            name: 'stroke-shape',
            visible: false,
          })

          const keyShape = group.addShape('rect', {
            attrs: {
              ...style,
              x: -width / 2,
              y: -height / 2,
              width,
              height,
              fill: colorSet.mainFill, // || '#3B4043',
              stroke: colorSet.mainStroke,
              lineWidth: 2,
              cursor: 'pointer',
              radius: height / 2 || 13,
              lineDash: [2, 2],
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'aggregated-node-keyShape',
          })

          let labelStyle = {}
          if (cfg.labelCfg) {
            labelStyle = Object.assign(labelStyle, cfg.labelCfg.style)
          }
          group.addShape('text', {
            attrs: {
              text: `${cfg.count}`,
              x: 0,
              y: 0,
              textAlign: 'center',
              textBaseline: 'middle',
              cursor: 'pointer',
              fontSize: 12,
              fill: '#fff',
              opacity: 0.85,
              fontWeight: 400,
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'count-shape',
            className: 'count-shape',
            draggable: true,
          })

          // tag for new node
          if (cfg.new) {
            group.addShape('circle', {
              attrs: {
                x: width / 2 - 3,
                y: -height / 2 + 3,
                r: 4,
                fill: '#6DD400',
                lineWidth: 0.5,
                stroke: '#FFFFFF',
              },
              // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
              name: 'typeNode-tag-circle',
            })
          }
          return keyShape
        },
        setState: (name: any, value: any, item: any) => {
          const group = item.get('group')
          if (name === 'layoutEnd' && value) {
            const labelShape = group.find(
              (e: any) => e.get('name') === 'text-shape'
            )
            if (labelShape) labelShape.set('visible', true)
          } else if (name === 'hover') {
            if (item.hasState('focus')) {
              return
            }
            const halo = group.find((e: any) => e.get('name') === 'halo-shape')
            const keyShape = item.getKeyShape()
            const colorSet = item.getModel().colorSet || colorSets[0]
            if (value) {
              halo && halo.show()
              keyShape.attr('fill', colorSet.activeFill)
            } else {
              halo && halo.hide()
              keyShape.attr('fill', colorSet.mainFill)
            }
          } else if (name === 'focus') {
            const stroke = group.find(
              (e: any) => e.get('name') === 'stroke-shape'
            )
            const keyShape = item.getKeyShape()
            const colorSet = item.getModel().colorSet || colorSets[0]
            if (value) {
              stroke && stroke.show()
              keyShape.attr('fill', colorSet.selectedFill)
            } else {
              stroke && stroke.hide()
              keyShape.attr('fill', colorSet.mainFill)
            }
          }
        },
        update: undefined,
      },
      'single-node'
    )

    // Custom real node
    G6.registerNode(
      'real-node',
      {
        draw(cfg: any, group: any) {
          let r = 30
          if (isNumber(cfg.size)) {
            r = cfg.size / 2
          } else if (isArray(cfg.size)) {
            r = cfg.size[0] / 2
          }
          const style = cfg.style || {}
          const colorSet = cfg.colorSet || colorSets[0]

          // halo for hover
          group.addShape('circle', {
            attrs: {
              x: 0,
              y: 0,
              r: r + 5,
              fill: style.fill || colorSet.mainFill || '#2B384E',
              opacity: 0.9,
              lineWidth: 0,
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'halo-shape',
            visible: false,
          })

          // focus stroke for hover
          group.addShape('circle', {
            attrs: {
              x: 0,
              y: 0,
              r: r + 5,
              fill: style.fill || colorSet.mainFill || '#2B384E',
              stroke: '#fff',
              strokeOpacity: 0.85,
              lineWidth: 1,
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'stroke-shape',
            visible: false,
          })

          const keyShape = group.addShape('circle', {
            attrs: {
              ...style,
              x: 0,
              y: 0,
              r,
              fill: colorSet.mainFill,
              stroke: colorSet.mainStroke,
              lineWidth: 2,
              cursor: 'pointer',
            },
            // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
            name: 'aggregated-node-keyShape',
          })

          let labelStyle = {}
          if (cfg.labelCfg) {
            labelStyle = Object.assign(labelStyle, cfg.labelCfg.style)
          }

          if (cfg.label) {
            const text = cfg.label
            let labelStyle: any = {}
            let refY = 0
            if (cfg.labelCfg) {
              labelStyle = Object.assign(labelStyle, cfg.labelCfg.style)
              refY += cfg.labelCfg.refY || 0
            }
            let offsetY = 0
            const fontSize = labelStyle.fontSize < 8 ? 8 : labelStyle.fontSize
            const lineNum = cfg.labelLineNum || 1
            offsetY = lineNum * (fontSize || 12)
            group.addShape('text', {
              attrs: {
                text,
                x: 0,
                y: r + refY + offsetY + 5,
                textAlign: 'center',
                textBaseLine: 'alphabetic',
                cursor: 'pointer',
                fontSize,
                fill: '#fff',
                opacity: 0.85,
                fontWeight: 400,
                stroke: global.edge.labelCfg.style.stroke,
              },
              // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
              name: 'text-shape',
              className: 'text-shape',
            })
          }

          // tag for new node
          if (cfg.new) {
            group.addShape('circle', {
              attrs: {
                x: r - 3,
                y: -r + 3,
                r: 4,
                fill: '#6DD400',
                lineWidth: 0.5,
                stroke: '#FFFFFF',
              },
              // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
              name: 'typeNode-tag-circle',
            })
          }

          return keyShape
        },
        setState: (name: any, value: any, item: any) => {
          const group = item.get('group')
          if (name === 'layoutEnd' && value) {
            const labelShape = group.find(
              (e: any) => e.get('name') === 'text-shape'
            )
            if (labelShape) labelShape.set('visible', true)
          } else if (name === 'hover') {
            if (item.hasState('focus')) {
              return
            }
            const halo = group.find((e: any) => e.get('name') === 'halo-shape')
            const keyShape = item.getKeyShape()
            const colorSet = item.getModel().colorSet || colorSets[0]
            if (value) {
              halo && halo.show()
              keyShape.attr('fill', colorSet.activeFill)
            } else {
              halo && halo.hide()
              keyShape.attr('fill', colorSet.mainFill)
            }
          } else if (name === 'focus') {
            const stroke = group.find(
              (e: any) => e.get('name') === 'stroke-shape'
            )
            const label = group.find((e: any) => e.get('name') === 'text-shape')
            const keyShape = item.getKeyShape()
            const colorSet = item.getModel().colorSet || colorSets[0]
            if (value) {
              stroke && stroke.show()
              keyShape.attr('fill', colorSet.selectedFill)
              label && label.attr('fontWeight', 800)
            } else {
              stroke && stroke.hide()
              keyShape.attr('fill', colorSet.mainFill) // '#2B384E'
              label && label.attr('fontWeight', 400)
            }
          }
        },
        update: undefined,
      },
      'aggregated-node'
    ) // 这样可以继承 aggregated-node 的 setState

    // Custom the quadratic edge for multiple edges between one node pair
    G6.registerEdge(
      'custom-quadratic',
      {
        setState: (name: any, value: any, item: any) => {
          const group = item.get('group')
          const model = item.getModel()
          if (name === 'focus') {
            const back = group.find(
              (ele: any) => ele.get('name') === 'back-line'
            )
            if (back) {
              back.stopAnimate()
              back.remove()
              back.destroy()
            }
            const keyShape = group.find(
              (ele: any) => ele.get('name') === 'edge-shape'
            )
            const arrow = model.style.endArrow
            if (value) {
              if (keyShape.cfg.animation) {
                keyShape.stopAnimate(true)
              }
              keyShape.attr({
                strokeOpacity: animateOpacity,
                opacity: animateOpacity,
                stroke: '#fff',
                endArrow: {
                  ...arrow,
                  stroke: '#fff',
                  fill: '#fff',
                },
              })
              if (model.isReal) {
                const { lineWidth, path, endArrow, stroke } = keyShape.attr()
                const back = group.addShape('path', {
                  attrs: {
                    lineWidth,
                    path,
                    stroke,
                    endArrow,
                    opacity: animateBackOpacity,
                  },
                  // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
                  name: 'back-line',
                })
                back.toBack()
                const length = keyShape.getTotalLength()
                keyShape.animate(
                  (ratio: any) => {
                    // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
                    const startLen = ratio * length
                    // Calculate the lineDash
                    const cfg = {
                      lineDash: [startLen, length - startLen],
                    }
                    return cfg
                  },
                  {
                    repeat: true, // Whether executes the animation repeatly
                    duration, // the duration for executing once
                  }
                )
              } else {
                let index = 0
                const lineDash = keyShape.attr('lineDash')
                const totalLength = lineDash[0] + lineDash[1]
                keyShape.animate(
                  () => {
                    index++
                    if (index > totalLength) {
                      index = 0
                    }
                    const res = {
                      lineDash,
                      lineDashOffset: -index,
                    }
                    // returns the modified configurations here, lineDash and lineDashOffset here
                    return res
                  },
                  {
                    repeat: true, // whether executes the animation repeatly
                    duration, // the duration for executing once
                  }
                )
              }
            } else {
              keyShape.stopAnimate()
              const stroke = '#acaeaf'
              const opacity = model.isReal
                ? realEdgeOpacity
                : virtualEdgeOpacity
              keyShape.attr({
                stroke,
                strokeOpacity: opacity,
                opacity,
                endArrow: {
                  ...arrow,
                  stroke,
                  fill: stroke,
                },
              })
            }
          }
        },
      },
      'quadratic'
    )

    // Custom the line edge for single edge between one node pair
    G6.registerEdge(
      'custom-line',
      {
        setState: (name: any, value: any, item: any) => {
          const group = item.get('group')
          const model = item.getModel()
          if (name === 'focus') {
            const keyShape = group.find(
              (ele: any) => ele.get('name') === 'edge-shape'
            )
            const back = group.find(
              (ele: any) => ele.get('name') === 'back-line'
            )
            if (back) {
              back.stopAnimate()
              back.remove()
              back.destroy()
            }
            const arrow = model.style.endArrow
            if (value) {
              if (keyShape.cfg.animation) {
                keyShape.stopAnimate(true)
              }
              keyShape.attr({
                strokeOpacity: animateOpacity,
                opacity: animateOpacity,
                stroke: '#fff',
                endArrow: {
                  ...arrow,
                  stroke: '#fff',
                  fill: '#fff',
                },
              })
              if (model.isReal) {
                const { path, stroke, lineWidth } = keyShape.attr()
                const back = group.addShape('path', {
                  attrs: {
                    path,
                    stroke,
                    lineWidth,
                    opacity: animateBackOpacity,
                  },
                  // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
                  name: 'back-line',
                })
                back.toBack()
                const length = keyShape.getTotalLength()
                keyShape.animate(
                  (ratio: any) => {
                    // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
                    const startLen = ratio * length
                    // Calculate the lineDash
                    const cfg = {
                      lineDash: [startLen, length - startLen],
                    }
                    return cfg
                  },
                  {
                    repeat: true, // Whether executes the animation repeatly
                    duration, // the duration for executing once
                  }
                )
              } else {
                const lineDash = keyShape.attr('lineDash')
                const totalLength = lineDash[0] + lineDash[1]
                let index = 0
                keyShape.animate(
                  () => {
                    index++
                    if (index > totalLength) {
                      index = 0
                    }
                    const res = {
                      lineDash,
                      lineDashOffset: -index,
                    }
                    // returns the modified configurations here, lineDash and lineDashOffset here
                    return res
                  },
                  {
                    repeat: true, // whether executes the animation repeatly
                    duration, // the duration for executing once
                  }
                )
              }
            } else {
              keyShape.stopAnimate()
              const stroke = '#acaeaf'
              const opacity = model.isReal
                ? realEdgeOpacity
                : virtualEdgeOpacity
              keyShape.attr({
                stroke,
                strokeOpacity: opacity,
                opacity: opacity,
                endArrow: {
                  ...arrow,
                  stroke,
                  fill: stroke,
                },
              })
            }
          }
        },
      },
      'single-edge'
    )

    const descendCompare = (p: any) => {
      // 这是比较函数
      return function (m: any, n: any) {
        const a = m[p]
        const b = n[p]
        return b - a // 降序
      }
    }

    const clearFocusItemState = (graph: any) => {
      if (!graph) return
      clearFocusNodeState(graph)
      clearFocusEdgeState(graph)
    }

    // 清除图上所有节点的 focus 状态及相应样式
    const clearFocusNodeState = (graph: any) => {
      const focusNodes = graph.findAllByState('node', 'focus')
      focusNodes.forEach((fnode: any) => {
        graph.setItemState(fnode, 'focus', false) // false
      })
    }

    // 清除图上所有边的 focus 状态及相应样式
    const clearFocusEdgeState = (graph: any) => {
      const focusEdges = graph.findAllByState('edge', 'focus')
      focusEdges.forEach((fedge: any) => {
        graph.setItemState(fedge, 'focus', false)
      })
    }

    // 截断长文本。length 为文本截断后长度，elipsis 是后缀
    const formatText = (
      text: any,
      length = 5 as any,
      elipsis = '...' as any
    ) => {
      if (!text) return ''
      if (text.length > length) {
        return `${text.substr(0, length)}${elipsis}`
      }
      return text
    }

    const labelFormatter = (text: any, minLength = 10) => {
      if (text && text.split('').length > minLength)
        return `${text.substr(0, minLength)}...`
      return text
    }
    const processNodesEdges = (
      nodes: any,
      edges: any,
      width: any,
      height: any,
      largeGraphMode: any,
      edgeLabelVisible: any,
      isNewGraph = false
    ) => {
      if (!nodes || nodes.length === 0) return {}
      const currentNodeMap = {}
      let maxNodeCount = -Infinity
      const paddingRatio = 0.3
      const paddingLeft = paddingRatio * width
      const paddingTop = paddingRatio * height
      nodes.forEach((node: any) => {
        node.type = node.level === 0 ? 'real-node' : 'aggregated-node'
        node.isReal = node.level === 0 ? true : false
        node.label = `${node.id}`
        node.labelLineNum = undefined
        node.oriLabel = node.label
        node.label = formatText(node.label, labelMaxLength, '...')
        node.degree = 0
        node.inDegree = 0
        node.outDegree = 0
        if (currentNodeMap[node.id]) {
          console.warn('node exists already!', node.id)
          node.id = `${node.id}${Math.random()}`
        }
        currentNodeMap[node.id] = node
        if (node.count > maxNodeCount) maxNodeCount = node.count
        const cachePosition = cachePositions
          ? cachePositions[node.id]
          : undefined
        if (cachePosition) {
          node.x = cachePosition.x
          node.y = cachePosition.y
          node.new = false
        } else {
          node.new = isNewGraph ? false : true
          if (manipulatePosition && !node.x && !node.y) {
            node.x =
              manipulatePosition.x + 30 * Math.cos(Math.random() * Math.PI * 2)
            node.y =
              manipulatePosition.y + 30 * Math.sin(Math.random() * Math.PI * 2)
          }
        }
      })

      let maxCount = -Infinity
      let minCount = Infinity
      // let maxCount = 0;
      edges.forEach((edge: any) => {
        // to avoid the dulplicated id to nodes
        if (!edge.id) edge.id = uniqueId('edge')
        else if (edge.id.split('-')[0] !== 'edge') edge.id = `edge-${edge.id}`
        // TODO: delete the following line after the queried data is correct
        if (!currentNodeMap[edge.source] || !currentNodeMap[edge.target]) {
          console.warn(
            'edge source target does not exist',
            edge.source,
            edge.target,
            edge.id
          )
          return
        }
        const sourceNode = currentNodeMap[edge.source]
        const targetNode = currentNodeMap[edge.target]

        if (!sourceNode || !targetNode)
          console.warn(
            'source or target is not defined!!!',
            edge,
            sourceNode,
            targetNode
          )

        // calculate the degree
        sourceNode.degree++
        targetNode.degree++
        sourceNode.outDegree++
        targetNode.inDegree++

        if (edge.count > maxCount) maxCount = edge.count
        if (edge.count < minCount) minCount = edge.count
      })

      nodes.sort(descendCompare(NODESIZEMAPPING))
      const maxDegree = nodes[0].degree || 1

      const descreteNodes: any = []
      nodes.forEach((node: any, i: any) => {
        // assign the size mapping to the outDegree
        const countRatio = node.count / maxNodeCount
        const isRealNode = node.level === 0
        node.size = isRealNode ? DEFAULTNODESIZE : DEFAULTAGGREGATEDNODESIZE
        node.isReal = isRealNode
        node.labelCfg = {
          position: 'bottom',
          offset: 5,
          style: {
            fill: global.node.labelCfg.style.fill,
            fontSize: 6 + countRatio * 6 || 12,
            stroke: global.node.labelCfg.style.stroke,
            lineWidth: 3,
          },
        }

        if (!node.degree) {
          descreteNodes.push(node)
        }
      })

      const countRange = maxCount - minCount
      const minEdgeSize = 1
      const maxEdgeSize = 7
      const edgeSizeRange = maxEdgeSize - minEdgeSize
      edges.forEach((edge: any) => {
        // set edges' style
        const targetNode = currentNodeMap[edge.target]

        const size =
          ((edge.count - minCount) / countRange) * edgeSizeRange +
            minEdgeSize || 1
        edge.size = size

        const arrowWidth = Math.max(size / 2 + 2, 3)
        const arrowLength = 10
        const arrowBeging = targetNode.size + arrowLength
        let arrowPath: any = `M ${arrowBeging},0 L ${
          arrowBeging + arrowLength
        },-${arrowWidth} L ${arrowBeging + arrowLength},${arrowWidth} Z`
        let d = targetNode.size / 2 + arrowLength
        if (edge.source === edge.target) {
          edge.type = 'loop'
          arrowPath = undefined
        }
        const sourceNode = currentNodeMap[edge.source]
        const isRealEdge = targetNode.isReal && sourceNode.isReal
        edge.isReal = isRealEdge
        const stroke = isRealEdge
          ? global.edge.style.realEdgeStroke
          : global.edge.style.stroke
        const opacity = isRealEdge
          ? global.edge.style.realEdgeOpacity
          : global.edge.style.strokeOpacity
        const dash = Math.max(size, 2)
        const lineDash = isRealEdge ? undefined : [dash, dash]
        edge.style = {
          stroke,
          strokeOpacity: opacity,
          cursor: 'pointer',
          lineAppendWidth: Math.max(edge.size || 5, 5),
          fillOpacity: 1,
          lineDash,
          endArrow: arrowPath
            ? {
                path: arrowPath,
                d,
                fill: stroke,
                strokeOpacity: 0,
              }
            : false,
        }
        edge.labelCfg = {
          autoRotate: true,
          style: {
            stroke: global.edge.labelCfg.style.stroke,
            fill: global.edge.labelCfg.style.fill,
            lineWidth: 4,
            fontSize: 12,
            lineAppendWidth: 10,
            opacity: 1,
          },
        }
        if (!edge.oriLabel) edge.oriLabel = edge.label
        if (largeGraphMode || !edgeLabelVisible) edge.label = ''
        else {
          edge.label = labelFormatter(edge.label, labelMaxLength)
        }

        // arrange the other nodes around the hub
        const sourceDis = sourceNode.size / 2 + 20
        const targetDis = targetNode.size / 2 + 20
        if (sourceNode.x && !targetNode.x) {
          targetNode.x =
            sourceNode.x + sourceDis * Math.cos(Math.random() * Math.PI * 2)
        }
        if (sourceNode.y && !targetNode.y) {
          targetNode.y =
            sourceNode.y + sourceDis * Math.sin(Math.random() * Math.PI * 2)
        }
        if (targetNode.x && !sourceNode.x) {
          sourceNode.x =
            targetNode.x + targetDis * Math.cos(Math.random() * Math.PI * 2)
        }
        if (targetNode.y && !sourceNode.y) {
          sourceNode.y =
            targetNode.y + targetDis * Math.sin(Math.random() * Math.PI * 2)
        }

        if (!sourceNode.x && !sourceNode.y && manipulatePosition) {
          sourceNode.x =
            manipulatePosition.x + 30 * Math.cos(Math.random() * Math.PI * 2)
          sourceNode.y =
            manipulatePosition.y + 30 * Math.sin(Math.random() * Math.PI * 2)
        }
        if (!targetNode.x && !targetNode.y && manipulatePosition) {
          targetNode.x =
            manipulatePosition.x + 30 * Math.cos(Math.random() * Math.PI * 2)
          targetNode.y =
            manipulatePosition.y + 30 * Math.sin(Math.random() * Math.PI * 2)
        }
      })

      descreteNodeCenter = {
        x: width - paddingLeft,
        y: height - paddingTop,
      }
      descreteNodes.forEach((node: any) => {
        if (!node.x && !node.y) {
          node.x =
            descreteNodeCenter.x + 30 * Math.cos(Math.random() * Math.PI * 2)
          node.y =
            descreteNodeCenter.y + 30 * Math.sin(Math.random() * Math.PI * 2)
        }
      })

      G6.Util.processParallelEdges(
        edges,
        12.5,
        'custom-quadratic',
        'custom-line'
      )
      return {
        maxDegree,
        edges,
      }
    }
    const getForceLayoutConfig = (
      graph: any,
      largeGraphMode: any,
      configSettings: any
    ) => {
      let {
        linkDistance,
        edgeStrength,
        nodeStrength,
        nodeSpacing,
        preventOverlap,
        nodeSize,
        collideStrength,
        alpha,
        alphaDecay,
        alphaMin,
      } = configSettings || { preventOverlap: true }

      if (!linkDistance && linkDistance !== 0) linkDistance = 225
      if (!edgeStrength && edgeStrength !== 0) edgeStrength = 50
      if (!nodeStrength && nodeStrength !== 0) nodeStrength = 200
      if (!nodeSpacing && nodeSpacing !== 0) nodeSpacing = 5

      const config = {
        type: 'gForce',
        minMovement: 0.01,
        maxIteration: 5000,
        preventOverlap,
        damping: 0.99,
        linkDistance: (d: any) => {
          let dist = linkDistance
          const sourceNode = nodeMap[d.source] || aggregatedNodeMap[d.source]
          const targetNode = nodeMap[d.target] || aggregatedNodeMap[d.target]
          // // 两端都是聚合点
          // if (sourceNode.level && targetNode.level) dist = linkDistance * 3;
          // // 一端是聚合点，一端是真实节点
          // else if (sourceNode.level || targetNode.level) dist = linkDistance * 1.5;
          if (!sourceNode.level && !targetNode.level) dist = linkDistance * 0.3
          return dist
        },
        edgeStrength: (d: any) => {
          const sourceNode = nodeMap[d.source] || aggregatedNodeMap[d.source]
          const targetNode = nodeMap[d.target] || aggregatedNodeMap[d.target]
          // 聚合节点之间的引力小
          if (sourceNode.level && targetNode.level) return edgeStrength / 2
          // 聚合节点与真实节点之间引力大
          if (sourceNode.level || targetNode.level) return edgeStrength
          return edgeStrength
        },
        nodeStrength: (d: any) => {
          // 给离散点引力，让它们聚集
          if (d.degree === 0) return -10
          // 聚合点的斥力大
          if (d.level) return nodeStrength * 2
          return nodeStrength
        },
        nodeSize: (d: any) => {
          if (!nodeSize && d.size) return d.size
          return 50
        },
        nodeSpacing: (d: any) => {
          if (d.degree === 0) return nodeSpacing * 2
          if (d.level) return nodeSpacing
          return nodeSpacing
        },
        onLayoutEnd: () => {
          if (largeGraphMode) {
            graph.getEdges().forEach((edge: any) => {
              if (!edge.oriLabel) return
              edge.update({
                label: labelFormatter(edge.oriLabel, labelMaxLength),
              })
            })
          }
        },
        tick: () => {
          graph.refreshPositions()
        },
      }

      if (nodeSize) config['nodeSize'] = nodeSize
      if (collideStrength) config['collideStrength'] = collideStrength
      if (alpha) config['alpha'] = alpha
      if (alphaDecay) config['alphaDecay'] = alphaDecay
      if (alphaMin) config['alphaMin'] = alphaMin

      return config
    }
    const hideItems = (graph: any) => {
      hiddenItemIds.forEach((id: any) => {
        graph.hideItem(id)
      })
    }
    const showItems = (graph: any) => {
      graph.getNodes().forEach((node: any) => {
        if (!node.isVisible()) graph.showItem(node)
      })
      graph.getEdges().forEach((edge: any) => {
        if (!edge.isVisible()) edge.showItem(edge)
      })
      hiddenItemIds = []
    }
    const handleRefreshGraph = (
      graph: any,
      graphData: any,
      width: any,
      height: any,
      largeGraphMode: any,
      edgeLabelVisible: any,
      isNewGraph: any
    ) => {
      if (!graphData || !graph) return
      clearFocusItemState(graph)
      // reset the filtering
      graph.getNodes().forEach((node: any) => {
        if (!node.isVisible()) node.show()
      })
      graph.getEdges().forEach((edge: any) => {
        if (!edge.isVisible()) edge.show()
      })

      let nodes = [],
        edges = []

      nodes = graphData.nodes
      const processRes = processNodesEdges(
        nodes,
        graphData.edges || [],
        width,
        height,
        largeGraphMode,
        edgeLabelVisible,
        isNewGraph
      )

      edges = processRes.edges

      graph.changeData({ nodes, edges })

      hideItems(graph)
      graph.getNodes().forEach((node: any) => {
        node.toFront()
      })

      // layout.instance.stop();
      // force 需要使用不同 id 的对象才能进行全新的布局，否则会使用原来的引用。因此复制一份节点和边作为 force 的布局数据
      layout.instance.init({
        nodes: graphData.nodes,
        edges,
      })

      layout.instance.minMovement = 0.0001
      // layout.instance.getCenter = d => {
      // 	const cachePosition = cachePositions[d.id];
      // 	if (!cachePosition && (d.x || d.y)) return [d.x, d.y, 10];
      // 	else if (cachePosition) return [cachePosition.x, cachePosition.y, 10];
      // 	return [width / 2, height / 2, 10];
      // }
      layout.instance.getMass = (d: any) => {
        const cachePosition = cachePositions[d.id]
        if (cachePosition) return 5
        return 1
      }
      layout.instance.execute()
      return { nodes, edges }
    }
    const getMixedGraph = (
      aggregatedData: any,
      originData: any,
      nodeMap: any,
      aggregatedNodeMap: any,
      expandArray: any,
      collapseArray: any
    ) => {
      let nodes: any = [],
        edges: any = []

      const expandMap = {},
        collapseMap = {}
      expandArray.forEach((expandModel: any) => {
        expandMap[expandModel.id] = true
      })
      collapseArray.forEach((collapseModel: any) => {
        collapseMap[collapseModel.id] = true
      })

      aggregatedData.clusters.forEach((cluster: any, i: any) => {
        if (expandMap[cluster.id]) {
          nodes = nodes.concat(cluster.nodes)
          aggregatedNodeMap[cluster.id].expanded = true
        } else {
          nodes.push(aggregatedNodeMap[cluster.id])
          aggregatedNodeMap[cluster.id].expanded = false
        }
      })
      originData.edges.forEach((edge: any) => {
        const isSourceInExpandArray = expandMap[nodeMap[edge.source].clusterId]
        const isTargetInExpandArray = expandMap[nodeMap[edge.target].clusterId]
        if (isSourceInExpandArray && isTargetInExpandArray) {
          edges.push(edge)
        } else if (isSourceInExpandArray) {
          const targetClusterId = nodeMap[edge.target].clusterId
          const vedge = {
            source: edge.source,
            target: targetClusterId,
            id: uniqueId('edge'),
            label: '',
          }
          edges.push(vedge)
        } else if (isTargetInExpandArray) {
          const sourceClusterId = nodeMap[edge.source].clusterId
          const vedge = {
            target: edge.target,
            source: sourceClusterId,
            id: uniqueId('edge'),
            label: '',
          }
          edges.push(vedge)
        }
      })
      aggregatedData.clusterEdges.forEach((edge: any) => {
        if (expandMap[edge.source] || expandMap[edge.target]) return
        else edges.push(edge)
      })
      return { nodes, edges }
    }
    const generateNeighbors = (
      centerNodeModel: any,
      step: any,
      maxNeighborNumPerNode: any = 5
    ) => {
      if (step <= 0) return undefined
      let nodes: any = [],
        edges: any = []
      const clusterId = centerNodeModel.clusterId
      const centerId = centerNodeModel.id
      const neighborNum = Math.ceil(Math.random() * maxNeighborNumPerNode)
      for (let i = 0; i < neighborNum; i++) {
        const neighborNode = {
          id: uniqueId('node'),
          clusterId,
          level: 0,
          colorSet: centerNodeModel.colorSet,
        }
        nodes.push(neighborNode)
        const dire = Math.random() > 0.5
        const source = dire ? centerId : neighborNode.id
        const target = dire ? neighborNode.id : centerId
        const neighborEdge = {
          id: uniqueId('edge'),
          source,
          target,
          label: `${source}-${target}`,
        }
        edges.push(neighborEdge)
        const subNeighbors = generateNeighbors(
          neighborNode,
          step - 1,
          maxNeighborNumPerNode
        )
        if (subNeighbors) {
          nodes = nodes.concat(subNeighbors.nodes)
          edges = edges.concat(subNeighbors.edges)
        }
      }
      return { nodes, edges }
    }
    const getNeighborMixedGraph = (
      centerNodeModel: any,
      step: any,
      originData: any,
      clusteredData: any,
      currentData: any,
      nodeMap: any,
      aggregatedNodeMap: any,
      maxNeighborNumPerNode: any = 5
    ) => {
      // update the manipulate position for center gravity of the new nodes
      manipulatePosition = { x: centerNodeModel.x, y: centerNodeModel.y }

      // the neighborSubGraph does not include the centerNodeModel. the elements are all generated new nodes and edges
      const neighborSubGraph: any = generateNeighbors(
        centerNodeModel,
        step,
        maxNeighborNumPerNode
      )
      // update the origin data
      originData.nodes = originData.nodes.concat(neighborSubGraph.nodes)
      originData.edges = originData.edges.concat(neighborSubGraph.edges)
      // update the origin nodeMap
      neighborSubGraph.nodes.forEach((node: any) => {
        nodeMap[node.id] = node
      })
      // update the clusteredData
      const clusterId = centerNodeModel.clusterId
      clusteredData.clusters.forEach((cluster: any) => {
        if (cluster.id !== clusterId) return
        cluster.nodes = cluster.nodes.concat(neighborSubGraph.nodes)
        cluster.sumTot += neighborSubGraph.edges.length
      })
      // update the count
      aggregatedNodeMap[clusterId].count += neighborSubGraph.nodes.length

      currentData.nodes = currentData.nodes.concat(neighborSubGraph.nodes)
      currentData.edges = currentData.edges.concat(neighborSubGraph.edges)
      return currentData
    }
    const getExtractNodeMixedGraph = (
      extractNodeData: any,
      originData: any,
      nodeMap: any,
      aggregatedNodeMap: any,
      currentUnproccessedData: any
    ) => {
      const extractNodeId = extractNodeData.id
      // const extractNodeClusterId = extractNodeData.clusterId;
      // push to the current rendering data
      currentUnproccessedData.nodes.push(extractNodeData)
      // update the count of aggregatedNodeMap, when to revert?
      // aggregatedNodeMap[extractNodeClusterId].count --;

      // extract the related edges
      originData.edges.forEach((edge: any) => {
        if (edge.source === extractNodeId) {
          const targetClusterId = nodeMap[edge.target].clusterId
          if (!aggregatedNodeMap[targetClusterId].expanded) {
            // did not expand, create an virtual edge fromt he extract node to the cluster
            currentUnproccessedData.edges.push({
              id: uniqueId('edge'),
              source: extractNodeId,
              target: targetClusterId,
            })
          } else {
            // if the cluster is already expanded, push the origin edge
            currentUnproccessedData.edges.push(edge)
          }
        } else if (edge.target === extractNodeId) {
          const sourceClusterId = nodeMap[edge.source].clusterId
          if (!aggregatedNodeMap[sourceClusterId].expanded) {
            // did not expand, create an virtual edge fromt he extract node to the cluster
            currentUnproccessedData.edges.push({
              id: uniqueId('edge'),
              target: extractNodeId,
              source: sourceClusterId,
            })
          } else {
            // if the cluster is already expanded, push the origin edge
            currentUnproccessedData.edges.push(edge)
          }
        }
      })
      return currentUnproccessedData
    }
    const examAncestors = (
      model: any,
      expandedArray: any,
      length: any,
      keepTags: any
    ) => {
      for (let i = 0; i < length; i++) {
        const expandedNode = expandedArray[i]
        if (!keepTags[i] && model.parentId === expandedNode.id) {
          keepTags[i] = true // 需要被保留
          examAncestors(expandedNode, expandedArray, length, keepTags)
          break
        }
      }
    }
    const manageExpandCollapseArray = (
      nodeNumber: any,
      model: any,
      collapseArray: any,
      expandArray: any
    ) => {
      manipulatePosition = { x: model.x, y: model.y }

      // 维护 expandArray，若当前画布节点数高于上限，移出 expandedArray 中非 model 祖先的节点)
      if (nodeNumber > NODE_LIMIT) {
        // 若 keepTags[i] 为 true，则 expandedArray 的第 i 个节点需要被保留
        const keepTags = {}
        const expandLen = expandArray.length
        // 检查 X 的所有祖先并标记 keepTags
        examAncestors(model, expandArray, expandLen, keepTags)
        // 寻找 expandedArray 中第一个 keepTags 不为 true 的点
        let shiftNodeIdx = -1
        for (let i = 0; i < expandLen; i++) {
          if (!keepTags[i]) {
            shiftNodeIdx = i
            break
          }
        }
        // 如果有符合条件的节点，将其从 expandedArray 中移除
        if (shiftNodeIdx !== -1) {
          let foundNode = expandArray[shiftNodeIdx]
          if (foundNode.level === 2) {
            let foundLevel1 = false
            // 找到 expandedArray 中 parentId = foundNode.id 且 level = 1 的第一个节点
            for (let i = 0; i < expandLen; i++) {
              const eNode = expandArray[i]
              if (eNode.parentId === foundNode.id && eNode.level === 1) {
                foundLevel1 = true
                foundNode = eNode
                expandArray.splice(i, 1)
                break
              }
            }
            // 若未找到，则 foundNode 不变, 直接删去 foundNode
            if (!foundLevel1) expandArray.splice(shiftNodeIdx, 1)
          } else {
            // 直接删去 foundNode
            expandArray.splice(shiftNodeIdx, 1)
          }
          // const removedNode = expandedArray.splice(shiftNodeIdx, 1); // splice returns an array
          const idSplits = foundNode.id.split('-')
          let collapseNodeId
          // 去掉最后一个后缀
          for (let i = 0; i < idSplits.length - 1; i++) {
            const str = idSplits[i]
            if (collapseNodeId) collapseNodeId = `${collapseNodeId}-${str}`
            else collapseNodeId = str
          }
          const collapseNode = {
            id: collapseNodeId,
            parentId: foundNode.id,
            level: foundNode.level - 1,
          }
          collapseArray.push(collapseNode)
        }
      }

      const currentNode = {
        id: model.id,
        level: model.level,
        parentId: model.parentId,
      }

      // 加入当前需要展开的节点
      expandArray.push(currentNode)

      graph.get('canvas').setCursor('default')
      return { expandArray, collapseArray }
    }
    const cacheNodePositions = (nodes: any) => {
      const positionMap = {}
      const nodeLength = nodes.length
      for (let i = 0; i < nodeLength; i++) {
        const node = nodes[i].getModel()
        positionMap[node.id] = {
          x: node.x,
          y: node.y,
          level: node.level,
        }
      }
      return positionMap
    }
    const stopLayout = () => {
      layout.instance.stop()
    }
    const bindListener = (graph: any) => {
      graph.on('keydown', (evt: any) => {
        const code = evt.key
        if (!code) {
          return
        }
        if (code.toLowerCase() === 'shift') {
          shiftKeydown = true
        } else {
          shiftKeydown = false
        }
      })
      graph.on('keyup', (evt: any) => {
        const code = evt.key
        if (!code) {
          return
        }
        if (code.toLowerCase() === 'shift') {
          shiftKeydown = false
        }
      })
      graph.on('node:mouseenter', (evt: any) => {
        const { item } = evt
        const model = item.getModel()
        const currentLabel = model.label
        model.oriFontSize = model.labelCfg.style.fontSize
        item.update({
          label: model.oriLabel,
        })
        model.oriLabel = currentLabel
        graph.setItemState(item, 'hover', true)
        item.toFront()
      })

      graph.on('node:mouseleave', (evt: any) => {
        const { item } = evt
        const model = item.getModel()
        const currentLabel = model.label
        item.update({
          label: model.oriLabel,
        })
        model.oriLabel = currentLabel
        graph.setItemState(item, 'hover', false)
      })

      graph.on('edge:mouseenter', (evt: any) => {
        const { item } = evt
        const model = item.getModel()
        const currentLabel = model.label
        item.update({
          label: model.oriLabel,
        })
        model.oriLabel = currentLabel
        item.toFront()
        item.getSource().toFront()
        item.getTarget().toFront()
      })

      graph.on('edge:mouseleave', (evt: any) => {
        const { item } = evt
        const model = item.getModel()
        const currentLabel = model.label
        item.update({
          label: model.oriLabel,
        })
        model.oriLabel = currentLabel
      })
      // click node to show the detail drawer
      graph.on('node:click', (evt: any) => {
        stopLayout()
        if (!shiftKeydown) clearFocusItemState(graph)
        else clearFocusEdgeState(graph)
        const { item } = evt

        // highlight the clicked node, it is down by click-select
        graph.setItemState(item, 'focus', true)

        if (!shiftKeydown) {
          // 将相关边也高亮
          const relatedEdges = item.getEdges()
          relatedEdges.forEach((edge: any) => {
            graph.setItemState(edge, 'focus', true)
          })
        }
      })

      // click edge to show the detail of integrated edge drawer
      graph.on('edge:click', (evt: any) => {
        stopLayout()
        if (!shiftKeydown) clearFocusItemState(graph)
        const { item } = evt
        // highlight the clicked edge
        graph.setItemState(item, 'focus', true)
      })

      // click canvas to cancel all the focus state
      graph.on('canvas:click', (evt: any) => {
        clearFocusItemState(graph)
        console.log(
          graph.getGroup(),
          graph.getGroup().getBBox(),
          graph.getGroup().getCanvasBBox()
        )
      })
    }
    const graph = new G6.Graph({
      container: 'mountNode2',
      // fitView: true,
      fitCenter: true,
      fitViewPadding: 10,
      layout: {
        type: 'dagre',
        animate: false,
        rankdir: 'LR',
        nodesep: 40,
        ranksep: 50,
        sortByCombo: true,
      },
      defaultEdge: {
        type: 'line-dash',
        loopCfg: {
          dist: 40,
        },
        labelCfg: {
          style: {
            fill: '#959FB4',
            stroke: '#E6E9EC',
          },
        },
      },
      defaultNode: {
        size: 20,
      },
      modes: {
        default: [
          'zoom-canvas',
          'drag-canvas',
          'drag-node',
          {
            type: 'tooltip',
            formatText(model: any) {
              console.log(model)
              switch (model.type) {
                case 'inner-img':
                  return `Agent: ${model.tooltip}`
                case 'my-image':
                  let modelDom = ''
                  let mysqlDom = `<div style="background: #f2f3f5;padding:8px;margin-top:16px;"><span style="color: #acb4c4;">端口：</span><span style="color: #38435a;">${model.port}</span></div>
                  <div style="background: #f2f3f5;padding:8px;"><span style="color: #acb4c4;">地址：</span><span  style="color: #1a80f2;">${model.address}</span></div>`
                  model.meta &&
                    model.meta.forEach((item: any) => {
                      modelDom += `<div style="background: #f2f3f5;padding:8px;margin-top:16px;"><span style="color: #acb4c4;">端口：</span><span style="color: #38435a;">${item.port}</span></div>
                  <div style="background: #f2f3f5;padding:8px;"><span style="color: #acb4c4;">地址：</span><span  style="color: #1a80f2;">${item.address}</span></div>`
                    })

                  return `<div style="width: 300px;padding: 8px;font-size:14px;">
                   <div style="color: #4a72ae;">DB: ${model.service_type}</div> 
                    ${modelDom || mysqlDom}
                  </div> `
                case 'user_auth':
                  return `项目: ${model.project__name}`
                case 'line-dash':
                  return `项目: ${model.project__name}`
                default:
                  return ``
              }
            },
            shouldUpdate: (e: any) => true,
          },
        ],
      },
      plugins: [minimap, tooltip],
    })

    graph.on('edge:mouseenter', function (evt) {
      const edge = evt.item as any
      const model = edge.getModel()
      model.oriLabel = model.label
      graph.setItemState(edge, 'hover', true)
    })
    graph.on('edge:mouseleave', function (evt) {
      const edge = evt.item as any
      graph.setItemState(edge, 'hover', false)
    })
    graph.on('node:mouseenter', (e: any) => {
      const item = e.item as any
      graph.getEdges().forEach(function (edge) {
        if (edge.getSource() === item) {
          graph.setItemState(edge.getTarget(), 'hover', true)
          edge.toFront()
        } else if (edge.getTarget() === item) {
          graph.setItemState(edge.getSource(), 'hover', true)
          edge.toFront()
        } else {
          graph.setItemState(edge, 'hover', false)
        }
      })
      graph.setItemState(e.item, 'hover', true)
    })

    graph.on('node:mouseleave', (e: any) => {
      const item = e.item as any
      console.log('e.item', e.item)
      graph.getEdges().forEach(function (edge) {
        if (edge.getSource() === item) {
          graph.setItemState(edge.getTarget(), 'hover', false)
        } else if (edge.getTarget() === item) {
          graph.setItemState(edge.getSource(), 'hover', false)
        } else {
          graph.setItemState(edge, 'hover', false)
        }
      })
      graph.setItemState(e.item, 'hover', false)
    })

    graph.on('edge:click', (e: any) => {
      const item = e.item as any
      const model = item.get('model')
      console.log('model', model)

      this.openRight(true, model)
      const hasSelected = item.hasState('selected')
      if (!hasSelected) {
        // 高亮
        const edges = graph.getEdges()
        edges.forEach((item: any) => {
          graph.setItemState(item, 'select', false)
          if (item.get('model').project_path === model.project_path) {
            graph.setItemState(item, 'select', true)
          }
        })
      } else {
        const edges = graph.getEdges()
        edges.forEach((item: any) => {
          graph.setItemState(item, 'select', false)
          if (item.get('model').project_path === model.project_path) {
            graph.setItemState(item, 'select', true)
          }
        })
      }
      // 找到相同的线路

      // 线的两边
      graph.getNodes().forEach((item: any) => {
        graph.setItemState(item, 'selected', false)
      })
      const edges = graph.getEdges()
      edges.forEach((item: any) => {
        if (item.get('model').project_path === model.project_path) {
          const node1 = item.getSource()
          const node2 = item.getTarget()
          graph.setItemState(node1, 'selected', true)
          graph.setItemState(node2, 'selected', true)
        }
      })
    })

    G6.registerNode(
      'user_auth',
      {
        draw(cfg: any, group: any) {
          const { title = '', size, label, stroke } = cfg
          // 设置
          const rectConfig = {
            width: 50,
            height: 50,
            lineWidth: 2,
            fontSize: 14,
            fill: '#fff',
            cursor: 'pointer',
            radius: 4,
            stroke: stroke,
            opacity: 1,
          }

          const nodeOrigin = {
            x: -rectConfig.width / 2,
            y: -rectConfig.height / 2,
          }

          let rect = group.addShape('circle', {
            attrs: {
              x: nodeOrigin.x,
              y: nodeOrigin.y,
              r: 25,
              ...rectConfig,
            },
            draggable: true,
            name: 'marker-circle',
          })
          group.addShape('text', {
            // attrs: style
            attrs: {
              x: nodeOrigin.x, // 居中
              y: nodeOrigin.y,
              textAlign: 'center',
              textBaseline: 'middle',
              text: cfg.project_id,
              fill: '#4a72ae',
              fontsize: 12,
            },
            name: 'text2-shape',
            // 设置 draggable 以允许响应鼠标的图拽事件
            draggable: true,
          })
          if (cfg.label) {
            // 如果有文本
            // 如果需要复杂的文本配置项，可以通过 labeCfg 传入
            // const style = (cfg.labelCfg && cfg.labelCfg.style) || {};
            // style.text = cfg.label;
            const label = group.addShape('text', {
              // attrs: style
              attrs: {
                x: nodeOrigin.x, // 居中
                y: nodeOrigin.y - 40,
                textAlign: 'center',
                textBaseline: 'middle',
                text:
                  cfg.label.length > 16
                    ? `${(cfg.label as string).substring(0, 16)}...`
                    : cfg.label,
                fill: '#4a72ae',
                fontsize: 12,
              },
              // 在 G6 3.3 及之后的版本中，必须指定 name，可以是任意字符串，但需要在同一个自定义元素类型中保持唯一性
              name: 'text-shape',
              // 设置 draggable 以允许响应鼠标的图拽事件
              draggable: true,
            })
          }

          return rect
        },
      },
      'circle'
    )

    function refreshDragedNodePosition(e: any) {
      const model = e.item.get('model')
      model.fx = e.x
      model.fy = e.y
    }
    graph.on('node:dragstart', (e) => {
      graph.get('layoutController').data.nodes.forEach((node: any) => {
        node.fx = node.x
        node.fy = node.y
      })
      // graph.layout()
      // refreshDragedNodePosition(e)
    })
    graph.on('node:drag', (e) => {
      refreshDragedNodePosition(e)
      // graph.layout()
    })
    const lineDash = [5, 5]
    G6.registerEdge(
      'line-dash',
      {
        drawShape: (cfg: any, group: any) => {
          const startPoint = cfg.startPoint
          const endPoint = cfg.endPoint
          const keyShape = group.addShape('path', {
            attrs: {
              endArrow: {
                path: 'M 0,0 L 6,4 L 6,-4 Z', // 自定义箭头路径
                d: 0, // 偏移量
                lineDash: [10, 0],
                lineWidth: 2,
                stroke: '#5a78e8',
                fill: '#5a78e8',
              },
              path: [
                ['M', startPoint.x, startPoint.y],
                ['L', endPoint.x, endPoint.y],
              ],
              stroke: '#5a78e8',
              lineDash: [5, 5],
              lineWidth: 2,
              strokeOpacity: '0.6',
              cursor: 'pointer',
            },
          })
          return keyShape
        },
        setState(name: any, value: any, node: any) {
          const group = node.getContainer()
          const shapes = group.get('children') // 顺序根据 draw 时确定
          if (name === 'hover') {
            if (value) {
              shapes.forEach((item: any) => {
                item.attr('strokeOpacity', 1.2)
              })
            } else {
              shapes.forEach((item: any) => {
                item.attr('strokeOpacity', 0.8)
              })
            }
          }
          if (name === 'select') {
            if (value) {
              shapes.forEach((item: any) => {
                item.attr('lineWidth', 3)
                item.attr('shadowColor', '#0099CC')
                item.attr('shadowBlur', 20)
                item.attr('strokeOpacity', 1)
              })
            } else {
              shapes.forEach((item: any) => {
                item.attr('lineWidth', 2)
                item.attr('shadowColor', 'none')
                item.attr('shadowBlur', '0')
                item.attr('strokeOpacity', 0.8)
              })
            }
          }
        },
      },
      'line'
    ) // 该自定义边继承 quadratic

    graph.data(this.souce_data)
    graph.render()
  }

  mounted() {
    this.init()
  }
}
